/**axios封装
 * 请求拦截、相应拦截、错误统一处理
*/
 import axios from "axios";
 import router from "../router";
 import _this from '../main';
 import i18n from "../lang";

 import { MessageBox, Message } from "element-ui";
 let showMsg=true;
//  let empArea=localStorage.getItem('empArea');
//  if(empArea=='青岛'){
//    // 环境的切换
//   axios.defaults.baseURL =
//     process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_QD;
//   }else if(empArea=='连云港'){
//     axios.defaults.baseURL =
//       process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_JS;
//   }else if(empArea=='科乐'){
//     axios.defaults.baseURL =
//       process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_CZ;
//   }else{
//     axios.defaults.baseURL =
//       process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_API;
//   }
 // 请求超时时间
 axios.defaults.timeout = 30000;
 // post请求头
 axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
 //请求拦截器 
 axios.interceptors.request.use(
   (config) => {
    let lang=window.localStorage.getItem('lang')?window.localStorage.getItem('lang'):'zn';
    // return
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    //  if(config.url){
    //   if(config.url.includes('lyg')||config.url.includes('qd')){
    //     let userInfo = localStorage.getItem("empUserInfo");
    //     let token=localStorage.getItem("bg_token");
    //     let head_token=localStorage.getItem('head_token');
    //     if(userInfo==null||userInfo==undefined){
    //       if(token){
    //         config.headers["token"] = token;
    //       }else{
    //         config.headers["token"] = '';
    //       }
    //     }else{
    //       token=localStorage.getItem("bg_token");
    //       config.headers["token"] = token;
    //     }
    //     if(head_token){
    //       config.headers["token"] = head_token;

    //     }
    //   }else if(!(config.url.includes('home/login/login'))){
    //       let userInfo = localStorage.getItem("userInfo");
    //       let token='';
    //       if(userInfo==null||userInfo==undefined){
    //         config.headers["token"] = token;
    //       }else{
    //         token=JSON.parse(localStorage.getItem("userInfo")).token;
    //         config.headers["token"] = token;
    //       }
    //     // console.log(2222);
    //   }
    //  }
     return config;
   },
   (error) => {
     return Promise.error(error);
   }
 );
 
 // 响应拦截器
 axios.interceptors.response.use(
   (response) => {
     if (response.status === 200) {
      const res = response.data;
      // res.status=2900;
      if(res.status===2900&&showMsg){
        Message({
          message: res.msg,
          type: "warning",
        });
        showMsg=false;
        setTimeout(()=>{
          showMsg=true;
        },2000)
        // 清除token
        localStorage.removeItem("userInfo");

        localStorage.removeItem("token");
        localStorage.removeItem("bg_token");
        localStorage.removeItem("head_token");
        localStorage.removeItem("empArea");
        localStorage.removeItem("empUserInfo");
        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        setTimeout(() => {
          router.replace({
            path: "/login",
          });
        }, 1000);
      }
      if(res.status===3000&&showMsg){
        Message({message: "登录过期，请重新登录！", type: "warning",});
        showMsg=false;
        setTimeout(()=>{showMsg=true;},2000)
        // 清除token
        localStorage.removeItem("bg_token");
        localStorage.removeItem("head_token");
        localStorage.removeItem("empArea");
        localStorage.removeItem("empUserInfo");


        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        setTimeout(() => {
          router.replace({
            path: "/emp_login",
          });
        }, 1000);
      }
      if(res.status==3001&&showMsg){
        Message({type:'error',message:res.msg});
        showMsg=false;
        setTimeout(()=>{showMsg=true;},2000)
        // return;
      }
      if(res.status==3900&&showMsg){
        Message({message: res.msg,type: "error"});
        showMsg=false;
        localStorage.removeItem("lang");
        setTimeout(() => {
          router.replace({
            path: "/price-login",
          });
        }, 1000);
      }

      if(res.status==3009){
        Message({
          message: response.config.url+"无权限！",
          type: "warning",
        });
        return;
      }
      return Promise.resolve(response);
     }
   },

   // 服务器状态码不是200的情况
   (error) => {
    //  return Promise.reject(error);
    if(error){
      //判断接口是否是圣诞活动的接口，不弹出信息，提示信息
      if(error.config.url.includes('home/Activity/drawInfo')){
        Message({ message: '服务器状态异常', type: "warning"});
      }else{
        MessageBox.alert(error.response.data, '服务器状态异常:'+error.request.status, {
          dangerouslyUseHTMLString: true,
          customClass: 'long-content-alert'
        });
      }
    
      
    }
    //  if (error.response.status) {
    //    switch (error.response.status) {
    //      // 401: 未登录
    //      // 未登录则跳转登录页面，并携带当前页面的路径
    //      // 在登录成功后返回当前页面，这一步需要在登录页操作。
    //      case 401:
    //        router.replace({
    //          path: "/login",
    //          query: { redirect: router.currentRoute.fullPath },
    //        });
    //        break;
    //      // 403 token过期
    //      // 登录过期对用户进行提示
    //      // 清除本地token
    //      // 跳转登录页面
    //      case 403:
    //        Message({
    //          message: "登录过期,请重新登录",
    //          type: "warning",
    //        });
    //        // 清除token
    //        localStorage.removeItem("token");
    //        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
    //        setTimeout(() => {
    //          router.replace({
    //            path: "/login",
    //            query: {
    //              redirect: router.currentRoute.fullPath,
    //            },
    //          });
    //        }, 1000);
    //        break;
    //      // 404请求不存在
    //      case 404:
    //        Message({
    //          message: "网络请求不存在",
    //          type: "error",
    //        });
    //        break;
    //      case 405:
    //        Message({
    //          message: "当前用户无权限",
    //          type: "warning",
    //        });
    //        break;
    //      // 其他错误，直接抛出错误提示
    //      default:
    //        Message({
    //          message: "网络异常,请联系系统管理员",
    //          type: "error",
    //        });
    //    }
    //    return Promise.reject(error.response);
    //  }
   }
 );
 /**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function get(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .get(url, {
         params: params,
       })
       .then((res) => {
        // console.log(res)
        if(res){
         resolve(res.data);
        }
       })
       .catch((err) => {
         reject(err);
       });
   });
 }
 /**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function post(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .post(url, params)
       .then((res) => {
         resolve(res.data);
       })
       .catch((err) => {
         reject(err.data);
       });
   });
 }
 /**
  * put方法，对应put请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function put(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .put(url, params)
       .then((res) => {
         resolve(res.data);
       })
       .catch((err) => {
         reject(err.data);
       });
   });
 }
 /**
  * del方法，对应delete请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function del(url, params) {
   return new Promise((resolve, reject) => {
     axios
       .delete(url, {
         params: params,
       })
       .then((res) => {
         resolve(res.data);
       })
       .catch((err) => {
         reject(err.data);
       });
   });
 }
 